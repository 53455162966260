import React, { useRef, useState } from 'react';
import Navbar from './Navbar';
import './Contact.css';
import emailJs from '@emailjs/browser';
import Footer from '../Footer/Footer';

const Contact = () => {
  const form = useRef();
  const [showNotification, setShowNotification] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailJs.sendForm('service_8dymewh', 'template_jm195va', form.current, 'ouJ__Nkgs7SURpLXm')
      .then((result) => {
        console.log(result.text);
        setShowNotification(true);
        setTimeout(() => {
          setShowNotification(false);
        }, 3000);
        form.current.reset();
      })
      .catch((err) => {
        console.log(err.text);
      });
  };

  console.log("Rendering Contact...");

  return (
    <div>
      <div className="contact-container">
        <Navbar />
        <div className="contact-overlay"></div>
        <h1 className='contact-heading'>Get In Contact with Us Today</h1>
        <p>We offer free initial consultations to discuss your legal needs and explore how we can assist you.<br></br> To schedule a consultation, please fill out the form below or contact us directly.<br></br> We look forward to speaking with you.</p>
        <div className="contact-grid">
          <div className="contact-form-container">
            <div className="contact-form-wrapper">
              <form className="contact-form" ref={form} onSubmit={sendEmail}>
                <input type="text" name="from_name" placeholder="Full Name*" required />
                <input type="text" name="from_contact" placeholder="Phone Number*" required />
                <input type="email" name="from_email" placeholder="Email*" required />
                <textarea name="message" placeholder="Your Message" required></textarea>
                <div className="button-container">
                  <button type="submit">Submit your Form</button>
                </div>
              </form>
            </div>

            {showNotification && (
              <div className="notification-success">
                Message sent successfully!
              </div>
            )}
          </div>
          <div className="contact-details">
            <div className="contact-info">
              <div className="contact-details">
                <p>
                  Thank you for your interest in Muhoro Advocates. We are dedicated to providing exceptional legal services and personalized attention to our clients. Whether you need representation for a personal injury case, assistance with family
                  law matters, or guidance in estate planning, we are here to help.
                </p>         
              </div>
              <div className="contact-details">
                <p>
                  "Have questions or need legal assistance? Our team is just a message away. Reach out to us using the form below, and one of our experienced attorneys will be in touch shortly. Your legal peace of mind is our priority."    
                </p>         
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Contact;