import './App.css';
import { BrowserRouter as Router, Routes, Route, } from 'react-router-dom';
import Home from './components/pages/Home';
import About from './components/pages/About';
import Practice from './components/pages/Practice';
import Contact from './components/pages/Contact';
import AboutMe from './components/pages/AboutMe';




function App() {
  return (
    <>
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/About" element={ <About />} /> 
        <Route path="/Practice" element={ <Practice />} />
        <Route path="/Contact" element={ <Contact />} />   
        <Route path="/about-me/:memberId" element={<AboutMe />} />
      </Routes>
    </Router>
    </>
  );
}

export default App;
