import React from 'react';
import { Link } from 'react-router-dom';
import './About.css';
import Navbar from './Navbar.jsx';
import Footer from '../Footer/Footer';

const About = () => {
  return (
    <div className="about-container">
      <Navbar />
      <section className="centered-section">
        <h3>
          <span className="bt_bb_headline_superheadline">
            The virtue of <strong>justice</strong> consists in <br /><strong>moderation,</strong> as regulated by <strong>wisdom.</strong>
          </span>
        </h3>
      </section>
      <section id="introduction" className="introduction-container">
        <div className="logo-column"></div>
        <div className="title-column">
          <img src="./assets/muhuro.png" alt="Logo" />
          <strong>MUHORO <br /> & ASSOCIATES ADVOCATES</strong>
        </div>
        <div className="content-column">
          <p>
            Is a medium-sized dynamic Law firm, established in April 1995 by the founding partner Mrs. Rosemary Wachu Muhoro. <br />
            <strong>Our Vision</strong> <br />Is to deliver efficient, transparent, and expedient legal services to our clients. <br />
            <strong>Our Mission</strong> <br /> Is to become a premier legal services provider, offering comprehensive legal solutions 
            to meet the needs of our clients and when required by all costs ensure the satisfaction of all of our clients.
          </p>
        </div>
        <div className="contact-details">
          <div className="office-details">
            <h3>Main Office</h3>
            <p>1st Floor of Museum Hill Centre Building, along Muthithi Road, Westlands, Nairobi</p>
          </div>
          <hr />
          <div className="email-details">
            <h3>E-MAIL</h3>
            <p>info@muhoroassociates.com</p>
          </div>
          <hr />
          <div className="telephone-details">
            <h3>TELEPHONE</h3>
            <p>0740793581</p>
          </div>
        </div>
      </section>
      <section className="areas-of-practice-container">
        <div className="skills padd-15">
          <div className="row">
            <h2>FIELDS OF EXPERTISE</h2>
            <div className="skills-item padd-15">
              <h5>Conveyancing</h5>
              <div className="progress">
                <div className="progress-in" style={{ width: '40%' }}></div>
                <div className="skill-percent">40%</div>
              </div>
            </div>
            <div className="skills-item padd-15">
              <h5>Civil Litigation</h5>
              <div className="progress">
                <div className="progress-in" style={{ width: '10%' }}></div>
                <div className="skill-percent">10%</div>
              </div>
            </div>
            <div className="skills-item padd-15">
              <h5>Commercial Litigation</h5>
              <div className="progress">
                <div className="progress-in" style={{ width: '10%' }}></div>
                <div className="skill-percent">10%</div>
              </div>
            </div>
            <div className="skills-item padd-15">
              <h5>Property, Environment and Land Court matters</h5>
              <div className="progress">
                <div className="progress-in" style={{ width: '10%' }}></div>
                <div className="skill-percent">10%</div>
              </div>
            </div>
            <div className="skills-item padd-15">
              <h5>Societies, Companies & Business Registration</h5>
              <div className="progress">
                <div className="progress-in" style={{ width: '10%' }}></div>
                <div className="skill-percent">10%</div>
              </div>
            </div>
            <div className="skills-item padd-15">
              <h5>Debt Collection</h5>
              <div className="progress">
                <div className="progress-in" style={{ width: '10%' }}></div>
                <div className="skill-percent">10%</div>
              </div>
            </div>
          </div>
        </div>
        <div className="practice-column">
          <ul className="practice-list">
            <li>
              <img src="../assets/assembly.png" alt="icon" className="icon" />
              <span>Conveyancing</span>
              <Link to="/practice#practice-areas-section">
                <img src="../assets/right-arrow.png" alt="arrow" className="arrow" />
              </Link>
            </li>
            <li>
              <img src="../assets/bank.png" alt="icon" className="icon" />
              <span>Banking & Financial Services</span>
              <Link to="/practice#practice-areas-section">
                <img src="../assets/right-arrow.png" alt="arrow" className="arrow" />
              </Link>
            </li>
            <li>
              <img src="../assets/employment.png" alt="icon" className="icon" />
              <span>Employment & Labour Relations</span>
              <Link to="/practice#practice-areas-section">
                <img src="../assets/right-arrow.png" alt="arrow" className="arrow" />
              </Link>
            </li>
            <li>
              <img src="../assets/debt-relief.png" alt="icon" className="icon" />
              <span>Debt Collection</span>
              <Link to="/practice#practice-areas-section">
                <img src="../assets/right-arrow.png" alt="arrow" className="arrow" />
              </Link>
            </li>
            <li>
              <img src="../assets/trade-mark.png" alt="icon" className="icon" />
              <span>Trade Marks & Copy Rights</span>
              <Link to="/practice#practice-areas-section">
                <img src="../assets/right-arrow.png" alt="arrow" className="arrow" />
              </Link>
            </li>
          </ul>
        </div>
      </section>
      <section className="statistics-section">
        <div className="statistics-item">
          <img src="../assets/legal-document.png" alt="Solved Cases" />
          <p>Solved Cases</p>
          <p className="number">500+</p>
        </div>
        <div className="statistics-item">
          <img src="../assets/calendar.png" alt="Successful Years" />
          <p>Successful Years</p>
          <p className="number">28</p>
        </div>
        <div className="statistics-item">
          <img src="../assets/handshake.png" alt="Satisfied Clients" />
          <p>Satisfied Clients</p>
          <p className="number">750+</p>
        </div>
      </section>
      <section id="practice-team" className="team-section">
        <div className="container">
          <h2>Our Team</h2>
          <div className="team-members">
          <div className="team-member">
              <img src="../assets/SAS_7584.jpg" alt="Rosemary Wachu Muhoro" />
              <h3>Rosemary Wachu Muhoro</h3>
              <h4>Managing Partner</h4>
              <p>
                Rosemary is the Managing Partner and an Advocate of the High Court of Kenya with over Thirty-Six (36) years of experience in legal practice and advisory services.
              </p>
              <Link to="/about-me/rosemary-wachu-muhoro" className="read-more-button">Read More</Link>
            </div>
            <div className="team-member">
              <img src="../assets/SAS_7666.jpg" alt="Peter Muhoro Kimani" />
              <h3>Peter Muhoro Kimani</h3>
              <h4>Astute Senior Advocate</h4>
              <p>
                Peter is an astute Senior Advocate of the High Court of Kenya with over Thirty-Six (36) years of experience in legal practice.
              </p>
              <Link to="/about-me/peter-muhoro-kimani" className="read-more-button">Read More</Link>
            </div>
            <div className="team-member">
              <img src="../assets/SAS_7620.jpg" alt="Faith Nduta Mwangi" />
              <h3>Faith Nduta Mwangi</h3>
              <h4>Associate</h4>
              <p>
                Faith is an Associate of the firm and an Advocate of the High Court of Kenya since May 2017.
              </p>
              <Link to="/about-me/faith-nduta-mwangi" className="read-more-button">Read More</Link>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default About;
