import React from 'react';
import { Link } from 'react-router-dom';
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaInstagram } from 'react-icons/fa';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="footer-widgets">
        <div className="footer-widget about-widget">
          <img src="../assets/muhuro.png" alt="Logo" className="footer-logo" />
          <p className="footer-description">Muhoro & Associates Advocates</p>
        </div>
        <div className="footer-widget">
          <h3 className="footer-title">Quick Links</h3>
          <ul className="footer-links">
            <li><Link to="/">Home</Link></li>
            <li><Link to="/About">About Us</Link></li>
            <li><Link to="/Practice">Practice Areas</Link></li>
            <li><Link to="/Contact">Contact Us</Link></li>
          </ul>
        </div>
        <div className="footer-widget">
          <h3 className="footer-title">Contact Us</h3>
          <p><strong>Address:</strong> 1st Floor of Museum Hill Centre<br /> Building, along Muthithi Road, Westlands,<br /> Nairobi</p>
          <p><strong>Email:</strong> info@muhoroassociates.com</p>
          <p><strong>Phone:</strong> <a href="tel:+254740793581">+254 740793581</a></p>
        </div>
        <div className="footer-widget">
          <h3 className="footer-title">Follow Us</h3>
          <ul className="footer-social-links">
            <li><a href="#"><FaFacebookF /> Facebook</a></li>
            <li><a href="#"><FaTwitter /> Twitter</a></li>
            <li><a href="#"><FaLinkedinIn /> LinkedIn</a></li>
            <li><a href="#"><FaInstagram /> Instagram</a></li>
          </ul>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} Muhoro & Associates Advocates. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
