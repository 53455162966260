import React from 'react';
import Navbar from './Navbar';
import './Practice.css';
import { Link } from 'react-router-dom';
import backgroundImage from '../../assets/images/SAS_7602.jpg';
import Footer from '.././Footer/Footer';



const Practice = () => {
  return (
    <div className='pps'>
      <div className="landing-section">
      <Navbar />
        <div className="background-image" style={{ backgroundImage: `url(${backgroundImage})` }}></div>
        
        <div className="overlay"></div>
        <div className="content">
        
          <div className="content-left">
            <h1>PRACTICE AREAS</h1>
            <p>We strive to offer efficient, cost-effective, and timely <br /> legal solutions and advice. Our Firm is committed to:</p>
            <ul>
              <li>Giving personalized and attentive focus to each brief or assignment</li>
              <li>Maintaining a skilled team proficient in Conveyancing, Commercial, Litigation, and General advisory legal areas</li>
              <li>Providing flexibility</li>
            </ul>
          </div>
        </div>
      </div>

      <section className="main-pra-section">
        <div className="main-pra-container">
          <div className="main-pra-content">
            <h2><strong>Main </strong>Practice Areas</h2>
            <p>Discover our core areas of expertise and how we can assist you.</p>
          </div>
          <div className="main-pra-list">
            <div className="main-pra-item">
              <h3>Banking and Financial Services</h3>
            </div>
            <div className="main-pra-item">
              <h3>Commercial Litigation</h3>
            </div>
            <div className="main-pra-item">
              <h3>Corporate and Commercial Law</h3>
            </div>
            <div className="main-pra-item">
              <h3>Employment & Labour Relations</h3>
            </div>
          </div>
        </div>
      </section>


      <section className="practice-areas-section">
        <div className="practice-areas-container">
          <div className="practice-area">
            <h3>Banking and Financial Services</h3>
            <p>In Banking and Financial Services, our team is adept at navigating the intricate legal and documentation requirements of debt and equity financing, banking regulations, and project management from inception to fruition. In addition, we extend our expertise to providing debt collection services to banks, financial institutions, and diverse clientele.</p>
          </div>
          <div className="consultation">
            <div className="consultation-title">Consultation</div>
            <button className='intouch'>
              <Link to="/Contact">Book a consultation</Link>
            </button> 
          </div>
          <div className="practice-area">
            <h3>Corporate and Commercial Law</h3>
            <p>Within Corporate and Commercial Law, we serve as strategic advisors to businesses 
              across a spectrum of industries including aviation, banking, real estate, and more, offering comprehensive support tailored to their unique needs.</p>
          </div>
          <div className="consultation1">
            <div className="consultation-title1">MEET OUR TEAM</div>
            <div className="consultation-description1">At Muhoro & Associates Advocates, we understand that legal challenges can be complex. Our dedicated 
              team of advocates guides you, providing personalized support tailored to your needs, committed to achieving the best outcomes for you.
            </div>
            <button className='intouch1'>
              <Link to="/About#team-section">Meet team</Link>
            </button>
          </div>
          <div className="practice-area">
            <h3>Dispute Resolution and Litigation</h3>
            <p>In Dispute Resolution and Litigation, our firm represents a diverse clientele in both the High Court and the Court of Appeal, as well as
               specialized tribunals. Whether we are prosecuting or defending a case, our approach emphasizes thorough preparation, realistic assessments, and 
               comprehensive guidance to ensure our clients are fully informed about all possible outcomes.</p>
          </div>
          <div className="practice-area">
            <h3>Real Estate and Conveyancing</h3>
            <p>In Real Estate and Conveyancing, our services encompass advising on financing options, navigating regulatory frameworks, and representing both
               developers and purchasers in transactions involving commercial and residential properties.</p>
          </div>
        </div>
      </section>
      <section className="get-in-touch-section">
        <div className="get-in-touch-container">
          <div className="get-in-touch-content">
            <h2>GET IN TOUCH TODAY</h2>
            <form className="contact-form1">
              <input type="text" placeholder="Full Name" />
              <hr />
              <input type="tel" placeholder="Phone Number" />
              <hr />
              <input type="email" placeholder="Email" />
              <hr />
              <textarea placeholder="Tell us your issue"></textarea>
              <button type="submit">Get Intouch</button>
            </form>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Practice;
