import React from 'react';
import { useParams } from 'react-router-dom';
import Navbar from './Navbar.jsx';
import Footer from '../Footer/Footer';
import './AboutMe.css';

const teamMembers = {
  'rosemary-wachu-muhoro': {
    name: 'Rosemary Wachu Muhoro',
    title: 'Managing Partner',
    image: '../assets/SAS_7584.jpg',
    bio: `
      Rosemary is the Managing Partner and an Advocate of the High Court of Kenya with over Thirty-Six (36) years’ experience in legal practice and advisory services.
      She is a Commissioner for Oaths, a Notary Public, and a Certified Public Secretary.
      Rosemary studied at the University of Nairobi qualifying in September 1986 with a Bachelor of Laws (LLB) Honors Degree. She thereafter proceeded to the Kenya School of Law where she obtained a Diploma in Legal Studies in 1987. Later, in December 2006, she attained a Masters of Arts in International Studies.
      Rosemary is an esteemed Conveyancing and Commercial Lawyer with extensive experience, offering a pragmatic approach to ensure favorable outcomes for her clients. In addition, she serves as the Secretary on the Board of Bible Life Fellowship, a collection of churches associated with Karura Community Chapel.
      She previously worked at Hamilton Harrison & Mathews Advocates and Waruhiu & Muite Advocates in their conveyancing and commercial Departments.
    `
  },
  'peter-muhoro-kimani': {
    name: 'Peter Muhoro Kimani',
    title: 'Astute Senior Advocate',
    image: '../assets/SAS_7666.jpg',
    bio: `
      Peter is an astute Senior Advocate of the High Court of Kenya with over Thirty (36) years of experience. He holds a Bachelor of Arts (Honors) from the Punjab University, India, a Bachelor of Laws (LLB) Honors Degree from The University of Pune India and a Post-Graduate Diploma in Law from the Kenya School of Law.
      Peter is a distinguished and reputable Senior Litigation, Commercial, and Conveyancing Advocate. He specializes in Dispute resolution, Litigation and Commercial law with a special focus on the following areas: Banking and financial services; Civil, Commercial, and Succession and Administration matters.
      Peter who was previously a Partner at Waruhiu & Gathuru Advocates has successfully represented clients in complex litigation and commercial transactions in the various courts in Kenya. Prior to joining Waruhiu & Gathuru Advocates in 1989, he had trained under and worked with Hamilton Harrison & Mathews Advocates in their Civil Litigation Department.
    `
  },
  'faith-nduta-mwangi': {
    name: 'Faith Nduta Mwangi',
    title: 'Associate',
    image: '../assets/SAS_7620.jpg',
    bio: `
      Faith is an Associate of the firm and an Advocate of the High Court of Kenya since May 2017. She holds a Bachelors of Law (LLB) Honors Degree from the Catholic University of Eastern Africa and a Post-Graduate Diploma from the Kenya School of Law. She is currently studying for a Master of Arts Degree in Leadership at Pan African Christian University.
      Faith is an emerging and agile Advocate with a diverse area of practice encompassing Conveyancing, Commercial Law, Legal Research, Legal Audit & Compliance, Litigation (especially in succession and property matters), Client Management, Due Diligence, and Risk Advisory. Previously, she gained valuable experience working at Sisule and Associates Advocates, Namasaka and Kariuki Advocates, and Kamau Kuria and Company Advocates.
    `
  }
};

const AboutMe = () => {
  const { memberId } = useParams();
  const member = teamMembers[memberId];

  if (!member) {
    return <div>Member not found</div>;
  }

  return (
    <div className="about-me-container">
      <Navbar />
      <div className="about-me-content">
        <div className="about-me-header">
          <img src={member.image} alt={member.name} className="about-me-image" />
          <div className="about-me-header-text">
            <h1>{member.name}</h1>
            <h2>{member.title}</h2>
          </div>
        </div>
        <div className="about-me-bio">
          <p>{member.bio}</p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AboutMe;
